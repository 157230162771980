import { Action } from '@ngrx/store';
import { Fuel } from '../../models/fuel';

export enum ActionTypes {
    UpdateDate = "[Date] Update"
}

export class UpdateDateAction implements Action {
    readonly type = ActionTypes.UpdateDate;
    constructor(public payload: Date) { }
}

export type Actions = UpdateDateAction;