import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fuel } from "../models/fuel";
import { Debt } from "../models/debts";
import { Payment } from "../models/payments";
import { ServerResponseModel } from "../models/serverresponsemodel";
import { ApiUrl } from "../models/apiurl";
import { RequestData } from "../models/requestdata";

@Injectable({
  providedIn: "root"
})
export class PaymentsService {
  private url = "/payments/get";
  private apiurl: ApiUrl;
  constructor(private http: HttpClient) {
    this.apiurl = new ApiUrl();
  }

  waterForm: FormGroup = new FormGroup({
    $key: new FormControl(null),
    AmountPaid: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")
    ])
  });

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    Description: new FormControl(""),
    Withdrawn: new FormControl(""),
    ARNumber: new FormControl(""),
    SelectedDateString: new FormControl(""),
    AmountPaid: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")
    ])
  });

  create(payment: Payment): Observable<Payment> {
    this.url = this.apiurl.BaseUrl + "/Payments/create";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    payment.Token = sessionStorage.getItem("token");
    payment.UserName = sessionStorage.getItem("username");

    return this.http
      .post<Payment>(this.url, this.formData(payment), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  createWater(payment: Payment): Observable<Payment> {
    this.url = this.apiurl.BaseUrl + "/Payments/createwater";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    payment.Token = sessionStorage.getItem("token");
    payment.UserName = sessionStorage.getItem("username");

    return this.http
      .post<Payment>(this.url, this.formData(payment), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  createSSS(payment: Payment): Observable<Payment> {
    this.url = this.apiurl.BaseUrl + "/Payments/createSss";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    payment.Token = sessionStorage.getItem("token");
    payment.UserName = sessionStorage.getItem("username");

    return this.http
      .post<Payment>(this.url, this.formData(payment), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  getAllByEmployee(id: number): Observable<Payment[]> {
    this.url = this.apiurl.BaseUrl + `/Payments/getAllByDriver/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getWaterPaymentsByEmployee(id: number): Observable<Payment[]> {
    this.url =
      this.apiurl.BaseUrl + `/Payments/getWaterPaymentByEmployee/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getSssPaymentsByEmployee(id: number): Observable<Payment[]> {
    this.url = this.apiurl.BaseUrl + `/Payments/getSssPaymentByEmployee/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getAllByDebts(id: number): Observable<Payment[]> {
    this.url = this.apiurl.BaseUrl + `/Payments/getallbydebts/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getAllByDriver(id: number): Observable<Payment[]> {
    this.url = this.apiurl.BaseUrl + `/Payments/getAllByDriver/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getAllByDriverDateReceived(id: number): Observable<Payment[]> {
    this.url = this.apiurl.BaseUrl + `/Payments/getAllByDriverDateReceived/${id}`;
    return this.http.get<Payment[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<Payment> {
    this.url = this.apiurl.BaseUrl + `/Payments/get/${id}`;
    return this.http.get<Payment>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  requestRentalDeletion(id: number): Observable<ServerResponseModel> {
    let request = new RequestData();
    request.Token = sessionStorage.getItem("token");
    request.UserName = sessionStorage.getItem("username");
    request.Id = id;

    this.url = this.apiurl.BaseUrl + `/Payments/requestDeletion`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.http
      .post<ServerResponseModel>(this.url, this.formData(request), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  cancelPayment(id: number): Observable<ServerResponseModel> {
    this.url = this.apiurl.BaseUrl + `/Payments/cancelpayment`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    let request = new RequestData();
    request.Token = sessionStorage.getItem("token");
    request.UserName = sessionStorage.getItem("username");
    request.Id = id;

    return this.http
      .post<any>(this.url, this.formData(request), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  cancelPaymentWater(id: number): Observable<ServerResponseModel> {
    this.url = this.apiurl.BaseUrl + `/Payments/cancelpaymentwater`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    let request = new RequestData();
    request.Token = sessionStorage.getItem("token");
    request.UserName = sessionStorage.getItem("username");
    request.Id = id;

    return this.http
      .post<any>(this.url, this.formData(request), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  formData(myFormData) {
    return Object.keys(myFormData)
      .map(function (key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(myFormData[key])
        );
      })
      .join("&");
  }

  // save(fuel: Fuel): Observable<Fuel> {
  //   this.url = `/fuel/create`;
  //   if (fuel.Id) this.url = `/fuel/update`;

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/x-www-form-urlencoded"
  //     })
  //   };

  //   return this.http
  //     .post<Fuel>(this.url, this.formData(fuel), httpOptions)
  //     .pipe(
  //       tap(data => {}),
  //       catchError(this.handleError)
  //     );
  // }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message
        }`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
