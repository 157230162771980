import { Action } from '@ngrx/store';
import * as LoadersActions from "./loaders.actions";
import { Loader } from '../../models/loader';

const initialState: Loader = {
    show: false
}

export function loadersReducer(state: Loader = initialState, action: LoadersActions.Actions) {
    switch (action.type) {
        case LoadersActions.ActionTypes.UpdateLoader:
            let _loader: Loader = {
                show: action.payload
            }
            return _loader;
        default:
            return state;
    }
}