import { Action } from '@ngrx/store';
import { MenuBar } from '../../models/menubar';

export enum ActionTypes {
    UpdateMenuBar = '[MenuBar] Update',
}

export class UpdateMenuBarAction implements Action {
    readonly type = ActionTypes.UpdateMenuBar;
    constructor(public payload: MenuBar) { }
}

export type Actions = UpdateMenuBarAction;