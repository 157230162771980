import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Fuel } from "../models/fuel";
import { Debt } from "../models/debts";
import { ApiUrl } from "../models/apiurl";
import { ServerResponseModel } from "../models/serverresponsemodel";
import { Transaction } from "../models/transaction";
import { RequestData } from "../models/requestdata";
import { ClientRequest } from "../models/clientrequest";
//import { Console } from "console";

@Injectable({
  providedIn: "root"
})
export class DebtsService {
  private url = "/debts/get";
  private apiurl: ApiUrl;
  constructor(private http: HttpClient) {
    this.apiurl = new ApiUrl();
  }

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    ChartOfAccountId: new FormControl("", [Validators.required]),
    Description: new FormControl(""),
    Amount: new FormControl("", [Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")])
  });

  getAllByEmployee(employeeId: number, coaId: number): Observable<Debt[]> {
    coaId = coaId | 0;
    this.url =
      this.apiurl.BaseUrl +
      `/Debts/getallbyemployee?employeeId=${employeeId}&coaId=${coaId}`;
    return this.http.get<Debt[]>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  getById(id: number): Observable<Fuel> {
    this.url = this.apiurl.BaseUrl + `/Debts/get/${id}`;
    return this.http.get<Fuel>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  formData(myFormData) {
    return Object.keys(myFormData)
      .map(function (key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(myFormData[key])
        );
      })
      .join("&");
  }

  create(transaction: Transaction): Observable<ServerResponseModel> {
    this.url = this.apiurl.BaseUrl + "/Debts/create";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    transaction.Token = sessionStorage.getItem("token");
    transaction.UserName = sessionStorage.getItem("username");

    return this.http
      .post<ServerResponseModel>(
        this.url,
        this.formData(transaction),
        httpOptions
      )
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  getTotalDebtsByEmployee(employeeId: number): Observable<ServerResponseModel> {
    this.url =
      this.apiurl.BaseUrl +
      `/Debts/getTotalDebtsByEmployee?employeeId=${employeeId}`;
    return this.http.get<ServerResponseModel>(this.url).pipe(
      tap(data => { }),
      catchError(this.handleError)
    );
  }

  cancel(id: number): Observable<ServerResponseModel> {
    let request = new RequestData();
    request.Token = sessionStorage.getItem("token");
    request.UserName = sessionStorage.getItem("username");
    request.Id = id;

    this.url = this.apiurl.BaseUrl + `/Debts/cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.http
      .post<any>(this.url, this.formData(request), httpOptions)
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  generatePromissory(clientRequest: ClientRequest): Observable<Transaction[]> {
    clientRequest.Token = sessionStorage.getItem("token");
    clientRequest.UserName = sessionStorage.getItem("username");
    this.url = this.apiurl.BaseUrl + `/Debts/generatePromissory`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.http
      .post<Transaction[]>(
        this.url,
        this.formData(clientRequest),
        httpOptions
      )
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  printPromissory(clientRequest: ClientRequest): Observable<Transaction[]> {
    clientRequest.Token = sessionStorage.getItem("token");
    clientRequest.UserName = sessionStorage.getItem("username");
    this.url = this.apiurl.BaseUrl + `/Debts/printPromissory`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.http
      .post<Transaction[]>(
        this.url,
        this.formData(clientRequest),
        httpOptions
      )
      .pipe(
        tap(data => { }),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message
        }`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
