import { Action } from '@ngrx/store';
import { Fuel } from '../../models/fuel';
import { DispatchFuel } from '../../models/dispatchfuel';
import { DispatchRental } from '../../models/dispatchrental';
import { ServerResponseModel } from '../../models/serverresponsemodel';

export enum ActionTypes {
  LoadAllByDriver = "[Rent] Load All by Driver",
  GetUnpaidRents = "[Unpaid Rents] Get unpaid rents"
}

export class LoadAllByDriverAction implements Action {
    readonly type = ActionTypes.LoadAllByDriver;
    constructor(public payload: DispatchRental[]) { }
}

export class GetUnpaidRentAction implements Action {
  readonly type = ActionTypes.GetUnpaidRents;
  constructor(public payload: ServerResponseModel) {}
}

export type Actions = LoadAllByDriverAction | GetUnpaidRentAction;