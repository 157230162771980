import { Injectable } from "@angular/core";
import { Action } from '@ngrx/store';
import { Credentials } from '../../models/credentials';

export enum ActionTypes {
    Login = '[Login Component] Login',
}

export class Login implements Action {
    readonly type = ActionTypes.Login;
    constructor(public payload: Credentials) { }
}

export type Actions = Login