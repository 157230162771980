import { Action } from '@ngrx/store';
import { Discount } from '../../models/discount';

export enum ActionTypes {
  AddDiscount = "[Discount] Add",
  LoadDiscount = "[Discount] Load",
  GetDiscount = "[Discount] Get"
}

export class AddDiscountAction implements Action {
    readonly type = ActionTypes.AddDiscount;
    constructor(public payload: Discount) { }
}

export class GetDiscountAction implements Action {
  readonly type = ActionTypes.GetDiscount;
  constructor(public payload: number) {}
}

export class LoadDiscountAction implements Action {
    readonly type = ActionTypes.LoadDiscount;
    constructor(public payload: Discount[]) { }
}

export type Actions = AddDiscountAction | GetDiscountAction | LoadDiscountAction